<template>
  <ValidationProvider
    ref="fieldCurrency"
    :name="$t('invoice.type')"
    :rules="required ? 'required' : ''"
    slim
    vid="invoicetype"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="invoiceTypeId"
        :error-messages="errors"
        :items="items"
        :label="hideLabel ? undefined : $t('invoice.type')"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :success="required ? valid : undefined"
        hide-details="auto"
        item-text="name"
        item-value="id"
        v-bind="obAttrs"
        @change="onChange"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import type { InvoiceTypeData } from "@planetadeleste/vue-mc-gw";
import { InvoiceType, InvoiceTypeCollection } from "@planetadeleste/vue-mc-gw";
import type { InvoiceTypeHelper } from "@/composables/invoiceType";
import useInvoiceType from "@/composables/invoiceType";
import { defaults, filter, find } from "lodash";
import type { InvoiceTypeCode } from "@/types/utils";

@Component
export default class InvoiceTypeSelect extends Vue {
  @VModel({ type: Number }) invoiceTypeId!: number;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly dgi!: boolean;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Array) readonly onlyCode!: InvoiceTypeCode[];

  obCollection: InvoiceTypeCollection = new InvoiceTypeCollection();
  obInvoiceTypeHelper: InvoiceTypeHelper | null = null;
  loading = false;

  get items(): Partial<InvoiceTypeData>[] {
    return this.obInvoiceTypeHelper
      ? filter(this.obInvoiceTypeHelper.items, (obItem) => {
          let bReturn = true;

          if (this.onlyCode?.length) {
            bReturn = this.onlyCode.includes(Number(obItem.code));
          }

          if (this.dgi && bReturn) {
            bReturn = obItem.dgi;
          }
          return bReturn;
        })
      : [];
  }

  created() {
    useInvoiceType().then((obObject) => {
      this.obInvoiceTypeHelper = obObject.obj;
    });
  }

  onChange(iValue: number) {
    const obModel = find(this.items, { id: iValue });
    this.$emit("change", new InvoiceType(obModel));
  }

  get obAttrs(): Record<string, any> {
    return defaults(this.$attrs, {
      dense: true,
      outlined: true,
    });
  }
}
</script>
