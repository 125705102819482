import { GwCacheModule } from "@/store/cache";
import { get, has, isObjectLike, isString, unset } from "lodash";
import type { CallbackWithResponse } from "@/types/utils";

export class CacheHelper {
  private readonly _cacheKey: string = "GW";

  constructor(sKey?: string) {
    if (sKey) {
      this._cacheKey = sKey;
    }
  }

  get cacheKey(): string {
    return this._cacheKey;
  }

  get cacheData() {
    return GwCacheModule.cache;
  }

  cache<T extends Record<string, any>>(
    obData?: T | string,
    sCacheKey?: string
  ): T | undefined {
    if (!sCacheKey) {
      sCacheKey = obData && isString(obData) ? obData : this.cacheKey;
    }

    if (obData && isObjectLike(obData)) {
      GwCacheModule.set({ [sCacheKey]: obData });
    }

    return get(this.cacheData, sCacheKey);
  }
}

export default function useCache<T extends Record<string, any>>(sKey = "GW") {
  const obCache = new CacheHelper(sKey);

  /**
   * Set/Get cache data
   * @param {Function} closure
   * @param {String} sCacheKey
   */
  const cache = async (
    closure: CallbackWithResponse<T>,
    sCacheKey?: string
  ): Promise<T | undefined> => {
    if (!sCacheKey) {
      sCacheKey = obCache.cacheKey;
    }

    if (has(obCache.cacheData, sCacheKey)) {
      return get(obCache.cacheData, sCacheKey) as T;
    }

    const obData = await closure();
    return obCache.cache<T>(obData);
  };

  /**
   * Remove cache data with passed key
   * @param {string} sCacheKey
   */
  const forget = (sCacheKey?: string) => {
    if (!sCacheKey) {
      sCacheKey = obCache.cacheKey;
    }

    unset(obCache.cacheData, sCacheKey);
  };

  return { cache, forget, obj: obCache };
}
