import type { InvoiceTypeData } from "@planetadeleste/vue-mc-gw";
import { InvoiceTypeCollection } from "@planetadeleste/vue-mc-gw";
import useCache from "./cache";

export class InvoiceTypeHelper {
  private _arItemList: InvoiceTypeData[] = [];

  get items(): InvoiceTypeData[] {
    return this._arItemList;
  }

  async load() {
    const { cache } = useCache<InvoiceTypeData[]>("InvoiceType.models");
    const arItems = await cache(async () => {
      const obCollection = new InvoiceTypeCollection();
      await obCollection.filterBy({ active: 1 }).list();

      return obCollection.getModelList() as InvoiceTypeData[];
    });

    if (arItems) {
      this._arItemList = arItems;
    }
  }
}

export default async function useInvoiceType() {
  const obHelper = new InvoiceTypeHelper();
  await obHelper.load();

  return { items: obHelper.items, obj: obHelper };
}
